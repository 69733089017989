import React from "react"
import Slider from "react-slick"
import { Box, Container, Flex, Heading } from "@chakra-ui/core"
import ReactMarkdown from "react-markdown"

var settings = {
  dots: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  pauseOnHover: true,
  autoplaySpeed: 6000,
}

const TestimonialText = ({ children }) => <Heading size="xl">{children}</Heading>

const Testimonial = ({ testimonials, name }) => {

  return (
    <Box w="100%" bgColor="gray.200">
      <Box py="50px" w="100%" maxW="1140px" pb="110px" mx="auto" px="15px">
        <Heading id="testimonials" pb="10px" pt="60px">
          {name}
        </Heading>
        <Box textAlign="center" height="350px">
          <Slider {...settings}>
            {testimonials.map(({ quote, name }, index) => {
              return (
                <Flex
                  w="100%"
                  p="30px"
                  h="100%"
                  direction="column"
                  bgColor="white"
                  key={index}
                >
                  <Container>
                    <ReactMarkdown
                      renderers={{
                        paragraph: TestimonialText,
                      }}
                      source={quote.childMarkdownRemark.rawMarkdownBody}
                    />
                  </Container>

                  <Heading mt="30px" size="md">
                    <em>{name}</em>
                  </Heading>
                </Flex>
              )
            })}
          </Slider>
        </Box>
      </Box>
    </Box>
  )
};

export default Testimonial;