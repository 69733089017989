import React from "react"
import {
  Box,
  Button,
  Flex,
  Divider,
  Heading,
  Icon,
  IconButton,
  Link,
  HStack,
  VStack,
  StackDivider,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Textarea,
} from "@chakra-ui/core"
import { BsPhone } from "react-icons/bs"
import { HiOutlineMailOpen } from "react-icons/hi"
import { ImFacebook, ImTwitter, ImYoutube } from "react-icons/im"

const Contact = ({
  title,
  siteName,
  phoneNumber,
  emailAddress,
  facebook,
  twitter,
  youTube,
  formSpreeId
}) => {
  return (
    <Box py="50px" w="100%" maxW="1140px" pb="110px" mx="auto" px="15px">
      <Heading id="contact" pb="10px" pt="60px">
        {title}
      </Heading>
      <Flex direction={{base: "column" , lg: "row" }} minH="450px" w="100%" justifyContent="space-between">
        <Flex direction="column">
          <VStack
            divider={<StackDivider borderColor="gray.300" />}
            spacing={6}
            align="stretch"
          >
            <Heading size="md">{siteName}</Heading>
            <Heading size="sm" display="flex" alignItems="center">
              <Icon as={HiOutlineMailOpen} boxSize={8} mr="10px" />
              {emailAddress}
            </Heading>
            <Heading size="sm" display="flex" alignItems="center">
              <Icon as={BsPhone} boxSize={8} mr="10px" />
              {phoneNumber}
            </Heading>
            <HStack
              divider={<StackDivider borderColor="gray.300" />}
              spacing={6}
              align="stretch"
            >
              <Link href={facebook} isExternal>
                <IconButton
                  colorScheme="blue"
                  fontSize="20px"
                  icon={<ImFacebook />}
                />
              </Link>
              <Link href={twitter} isExternal>
                <IconButton
                  colorScheme="teal"
                  fontSize="20px"
                  icon={<ImTwitter />}
                />
              </Link>
              <Link href={youTube} isExternal>
                <IconButton
                  colorScheme="red"
                  fontSize="20px"
                  icon={<ImYoutube />}
                />
              </Link>
            </HStack>
          </VStack>
        </Flex>
        <Divider orientation="vertical" mx="15px" />
        <Box w={{base:"100%"}}>
        <form
          action={`https://formspree.io/f/${formSpreeId}`}
          method="POST"
            className="contactForm"
            width="100%"
        >
          <VStack
            divider={<StackDivider borderColor="gray.300" />}
            spacing={6}
            align="stretch"
            pt={{base: "30px"}}
          >
            <FormControl id="name" isRequired>
              <FormLabel>Your Name</FormLabel>
              <Input name="name" type="text" />
              <FormHelperText>What may I call you?</FormHelperText>
            </FormControl>
            <FormControl id="email" isRequired>
              <FormLabel>Email address</FormLabel>
              <Input name="_replyto" type="email" />
              <FormHelperText>I'll never share your email.</FormHelperText>
            </FormControl>
            <FormControl id="message" isRequired>
              <FormLabel>Message</FormLabel>
              <Textarea
                name="message"
                placeholder="What can I help you with?"
              />
            </FormControl>
            <Button
              leftIcon={<HiOutlineMailOpen />}
              colorScheme="blue"
              variant="solid"
              type="submit"
            >
              Send
            </Button>
          </VStack>
          </form>
          </Box>
      </Flex>
    </Box>
  )
}

export default Contact
