import React from "react"
import { Box, Button, Flex, Link, Text, Heading } from "@chakra-ui/core"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"

const BannerTextArea = ({children}) => <Text pb="15px">{children}</Text>

const Banner = () => {
  const {
    contentfulHeaderBanner: {
      bannerHeading,
      bannerImage,
      bannerText,
      bannerSubHeading,
    },
  } = useStaticQuery(BannerQuery)
  return (
    <Box className="banner" position="relative" h="100vh" bgColor="gray.400">
      <Img
        fluid={bannerImage.fluid}
        objectFit="cover"
        objectPosition="50% 50%"
      />
      <Box
        position="absolute"
        top={{ base: "0", md: "600px", lg: "0" }}
        mt="60px"
        h="calc(100vh-60px)"
        w="100%"
      >
        <Box w="100%" maxW="1140px" pt="50px" color="white" mx="auto" px="15px">
          <Flex
            maxW={{ base: "450px", md: "100%", lg: "450px" }}
            minH={{ base: "400px", md: "200px", lg: "400px" }}
            maxH={{ base: "800px", md: "300px", lg: "800px" }}
            direction="column"
            justifyContent="space-between"
            p="20px"
            bgColor="rgba(0,0,0,.4)"
          >
            <Box>
              <Heading size="2xl" pb="10px">
                {bannerHeading}
              </Heading>
              <Heading size="md" pb="10px">
                {bannerSubHeading}
              </Heading>
            </Box>

            <ReactMarkdown
              renderers={{
                paragraph: BannerTextArea,
                heading: Heading,
              }}
              source={bannerText.childMarkdownRemark.rawMarkdownBody}
            />
            <Link href="#contact">
              <Button colorScheme="blue">Book Now</Button>
            </Link>
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}

export default Banner

const BannerQuery = graphql`
  query BannerQuery {
    contentfulHeaderBanner {
      bannerHeading
      bannerImage {
        fluid(maxWidth: 1500) {
          aspectRatio
          base64
          src
          srcSet
        }
      }
      bannerSubHeading
      bannerTags
      bannerText {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
    }
  }
`
