import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "../styles/style.css"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Banner from "../components/banner"
import Service from "../components/service"
import Testimonial from "../components/testimonial"
import Contact from "../components/contact"

const IndexPage = () => {
  let {
    contentfulPageContentOrder: { contentAreas },
    contentfulSiteMetaData: {siteName},
  } = useStaticQuery(HomePageQuery)
  return (
    <Layout>
      <SEO />
      <Banner />
      {contentAreas.map(contentArea => {
        switch (contentArea.__typename) {
          case "ContentfulServicesContainer":
            return (
              <Service
                key={contentArea.id}
                name={contentArea.name}
                services={contentArea.services}
              />
            )
          case "ContentfulTestimonialsContainer":
            return (
              <Testimonial
                key={contentArea.id}
                name={contentArea.name}
                testimonials={contentArea.testimonial}
              />
            )
          case "ContentfulContactInfo":
            return (
              <Contact
                key="contentArea.id"
                {...contentArea}
                siteName={siteName}
              />
            )
          default:
            return null
        }
      })}
    </Layout>
  )
}

export default IndexPage

const HomePageQuery = graphql`
  query HomePageContentQuery {
    contentfulSiteMetaData {
      siteName
    }
    contentfulPageContentOrder(name: { eq: "Home Page" }) {
      contentAreas {
        ... on ContentfulServicesContainer {
          id
          name
          services {
            serviceDescription {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
            serviceImage {
              fluid(maxHeight: 200) {
                srcSet
                base64
                aspectRatio
                sizes
                src
              }
            }
            serviceName
            id
          }
        }
        ... on ContentfulTestimonialsContainer {
          id
          name
          testimonial {
            id
            name
            quote {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
        }
        ... on ContentfulContactInfo {
          id
          title
          emailAddress
          facebook
          phoneNumber
          twitter
          youTube
          formSpreeId
        }
      }
    }
  }
`
